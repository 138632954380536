import { Container } from '@chakra-ui/react'
import { Script } from 'gatsby'

const MakeTimeChallengePage = () => {
  return (
    <>
      <Container
        maxW="container.full"
        minH="100%"
        py={0}
        px={0}
        paddingBottom="0!important"
        id="experience-63bc6313b4aa3"
        data-aspectratio="0.59451928"
        data-mobile-aspectratio="0.23478261"
      >
        <iframe
          className={'ceros-experience'}
          src="https://view.ceros.com/hello-sunshine/make-time-challenge"
          title="Make Time Challenge"
          seamless={true}
          allowFullScreen
          width={'100%'}
          height={'800'}
        ></iframe>
      </Container>
      <Script
        src={'https://view.ceros.com/scroll-proxy.min.js'}
        data-ceros-origin-domains="view.ceros.com"
      />
    </>
  )
}

export default MakeTimeChallengePage
